@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@500;600;700&family=Roboto&display=swap);
.modalContainer {
  padding: 0 px 0 px;
}

.ui.modal {
  width: 1000px;
}

.modalBodyContainer {
  margin: 15px 10px;
}

/* font-family: 'Montserrat', sans-serif;
font-family: 'Roboto', sans-serif; */

.modalLine {
  margin-top: 5px;

  color: #F2F2F2;
  background-color: #F2F2F2;
  height: 5;
}

.modalHeader {
  display: flex;
  padding: 10px 25px 5px 15px;
  justify-content: space-between;
  align-items: center;
}

.modalTitle {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
}

.modalCloseButton {
  padding: 10px 10px;
  background-color: white;
}

.modalCloseButton:hover {
  background-color: #ebebeb;
}

.modalFooter {
  clear: both;
  margin-bottom: 20px;
}

.ui.modal>.content {
  padding: 0px;
}

.confirmModal.ui.modal>.content,
.denyConfirmModal.ui.modal>.content,
.approveConfirmModal.ui.modal>.content,
.sendBackConfirmModal.ui.modal>.content,
.historyModal.ui.modal>.content,
.detailsModal.ui.modal>.content {
  display: block;
  width: 100%;
  font-size: 1em;
  line-height: 1.4;
  padding: 1.5rem;
  background: #fff;
}

.selectedBorderBox {
  margin: auto;
  border: #333 2px solid;
  border-top: none;
  border-radius: 0 0 5px 5px;
  width: 80%;

  transition: 2s opacity ease-in-out, 2s -webkit-transform ease-in-out;

  transition: 2s opacity ease-in-out, 2s transform ease-in-out;

  transition: 2s opacity ease-in-out, 2s transform ease-in-out, 2s -webkit-transform ease-in-out;
}



.entryModalButton {
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  font-size: 14px;
  cursor: pointer;
  display: block;
  background: #32C5FF;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.3);
  border-radius: 4px;
}

.uploadedPicContainer{
  position: relative;
  background-color: #000;
  color: #fff;
  padding: 8px;
  border-radius: 20px;
  margin-top: 40px;
}

.uploadedPicContainer:after {
  content: '';
  position: absolute;
  top: -58px;
  left: 50%;
  margin-left: -30px;
  border-width: 30px;
  border-style: solid;
  border-color: transparent transparent #000 transparent;
}

.uploadedPicInner{
  border-radius: 20px;
  display: flex;
  flex-direction: column;
}

.descriptionInput {
  resize: none;
  overflow: hidden;
  min-height: 50px;
  max-height: 100px;
}
.selectedFileName{
  text-align: center;
  color: #fff !important;
  margin-top: 10px;
}
.switch {
    position: relative;
    display: inline-block;
    width: 44px;
    height: 22px;
  }
  
  .switch input { 
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 18px;
    width: 18px;
    left: 4px;
    bottom: 2px;
    background-color: white;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #32C5FF;
  }
    
  input:focus + .slider {
    box-shadow: 0 0 1px #00667E;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(20px);
    transform: translateX(20px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }
.dzStyleKapak{
    border: 'dashed 3px #eee';
    border-color: #eee;
    border-radius: 5px;
    padding-top: 30px;
    text-align: center;
    height: 277px;
    width:948px;
}

.dzStyleFoto{
    border: 'dashed 3px #eee';
    border-color: #eee;
    border-radius: 5px;
    padding-top: 30px;
    text-align: center;
    height: 250px;
    width:462px;
}
.siparisMain {
  padding: 10px;
}

.siparisMiddleRow {
  padding-top: 12px;
  padding-bottom: 12px;
  display: flex;
  justify-content: space-between;
}

.siparisMainInfo {
  width: 45%;
}

.siparisImage {
  width: 462px;
  height: 250px;
  border: 2px dashed #E0E0E0;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

}

.siparisMain p {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #4F4F4F;
}

.siparisCheckbox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 300px;
}

.siparisCheckboxFont {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #828282;
}

.siparisHeaderPhotoContainer {
  width: 948px;
  height: 277px;
  border: 2px dashed #E0E0E0;
  box-sizing: border-box;
  border-radius: 4px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.siparisHeaderPhotoContainer:hover {
  cursor: pointer;
}

.siparisHeaderPhoto {
  width: 946px;
  height: 275px;
}

.siparisFoto {
  width: 462px;
  height: 250px;
  border: 2px dashed #E0E0E0;
  box-sizing: border-box;
  border-radius: 4px;
}

.siparisPhotoUpload {
  height: 100px;
  width: auto;
}

.siparisPhotoUploadDescriptionTitle {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  color: #4F4F4F;
  padding-top: 10px;
}

.siparisPhotoUploadDescription {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #4F4F4F;
  padding-top: 10px;
}

.siparisImagePhoto {
  width: 460px;
  height: 248px;
}

.siparisImage:hover {
  cursor: pointer;
}

.siparisSubmitButtons {
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
}

.modalLoader {
  padding-top: 150px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
}

.siparisStepContainer {
  margin-top: 24px;
  padding: 24px;
  display: flex;
  width: 100%;
  border: 1px solid #E0E0E0;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
}

.siparisStepFormLeft {
  width: 410px;
}

.siparisStepsTitle {
  margin-top: 50px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
}

.siparisStepsLine {
  margin-top: 10px;
  width: 100%;
  height: 3px;
  background: #E0E0E0;
}

.siparisStepListItem {
  width: 100%;
  height: 60px;
  background: #F2F2F2;
  border-radius: 4px;
  display: flex;
  margin-top: 16px;
  justify-content: space-between;
}

.siparisStepItemTitle {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  color: #4F4F4F;
  margin-left: 25px;
}

.siparisStepItemDate {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: #BDBDBD;
  padding-right: 25px;
}

.siparisStepListIconDiv {
  width: 30px;
  height: 30px;
  background-color: F2F2F2;
  display: flex;
  margin-right: 20px;
  background-color: F2F2F2;
  align-items: center;
  justify-content: center;
}

/* .siparisStepListIconDiv:hover {
  background-color: white;
}

.logo-img:hover {
  filter: invert(1);
  // or to blue
  // filter: invert(1) sepia(1) saturate(5) hue-rotate(175deg);
} */
.siparisHeaderTitleContainer {
    position: absolute;
    display: flex;
    flex-direction: column;
    bottom: 0px;
    left: 0px;
}

.siparislerMainBanner {
    width: 100%;
}

.siparisPhoto {
    width: 384px;
    height: 210px;
}

.siparisItemContainer {
    margin-right: 8px;
    margin-left: 8px;
    margin-top: 50px;
    width: 384px;
    background: #FFFFFF;
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.14);
    border-radius: 4px;
}

.siparisItemWindow {
    background-color: white;
    border-radius: 10px;
    padding: 5px;
}

.siparisActions {
    display: flex;
    justify-content: space-between;
}

.siparisInfoContainer {
    padding: 24px;
    height: 300px;
    display: flex;
    justify-content: space-between;
    align-content: space-around;
    flex-wrap: nowrap;
    flex-direction: column;
}

.siparisDashboardIdeaImage {
    Width: 13.5px;
    Height: 18px;
}

.siparisCurrentStep {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
    color: #BDBDBD;
}

.siparisTitle {
    margin-top: 10px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 30px;
    color: #4F4F4F;
}

.siparisDescription {
    margin-top: 10px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #828282;
    word-wrap: break-word;
}

.siparisPaylas {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    text-align: right;
    color: #828282;
}

.siparisIdeaCount {
    margin-left: 8px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: #4F4F4F;
}

.siparisListContainer {
    width: 100%;
    background: #FFFFFF;
    display: flex;
    border-left: 1px solid #E0E0E0;
}

.siparisBodyContainer {
    background-color: #ffffff;
    width: 100%;
}

.siparisSearchCriteria {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #828282;
    padding: 5px;
    padding-left: 10px;
}

.siparisSearchComp {
    padding: 6px;
    display: flex;
    align-items: center;
}

.siparisSearchButton {
    padding: 6px;
    display: flex;
    padding-left: 10px;
}

.siparisGridHeader {

    display: flex;
    align-items: center;
    width: 1800px;
    height: 50px;
    margin: auto;

    background: #32C5FF;
    border-radius: 4px 4px 0px 0px;

}

.siparisGridHeaderItem {
    width: 150px;
    text-align: center;
    border-right: 1px solid #FFFFFF;
    height: 100%;
    padding-top: 5px;
}

.siparisGridScroll {
    height: 60vh;
    overflow-x: scroll;
    cursor: default;
}

.siparisGridHeaderText {

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    /* identical to box height, or 150% */


    /* Grays/Gray 6 */

    color: #F2F2F2;

}

.siparisListItemBox {
    padding: 10px 15px;
    /* border:1px solid  #E0E0E0; */
    border-bottom: 1px solid #E0E0E0;
    border-right: 1px solid #E0E0E0;
}

.siparisItemDate {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    text-align: right;
    color: #828282;
}

.siparisStepDates {
    display: flex;
    justify-content: space-between;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    color: #828282;
}

.siparisGuncelleBlock {
    background: white;
    display: flex;
    justify-content: space-around;
    padding-top: 10px;
    padding-bottom: 20px;
}

.siparisPaginationContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 10px 20px;
}

.kayitBulunamadi {
    margin: 40px;
    display: flex;
    justify-content: center;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: #828282;
    padding: 10px;
    padding-left: 10px;
}
.navbarContainer{
    padding: 20px;
    height: 80px;
    display: flex;
    background-color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.userNameLink{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #828282;
}
.userNameContainer{
    padding: 8px 8px;
}

.navBarLink {
    padding: 8px 18px;
    /* border: 2px solid #32C5FF; */
    border:none;
    box-sizing: border-box;
    border-radius: 4px;
    color: #32C5FF;
    background-color: white;
  
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 30px;
  
  }
  
  .navBarLink:hover {
    border:none;
    /* 2px solid #0594b4;*/
    color: #0594b4;
  }
.loginBackground {
    background: no-repeat center center fixed;
    background-size: cover;
    height: 100%;
}

.loginContainer {
    /* width: 1200px; */
    height: 100%;
    display: flex;
    margin: auto;
    /* background: white; */
    justify-content: center;
    /* background-image: url('/assets/background.jpg') */
}

.loginFormBox {
    width: 500px;
    
    display: flex;
    vertical-align: middle;
    flex-direction: column;
    justify-content: center;
    background: rgba(0, 0, 0, .5);
    border: 1px solid #2e2d2d;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 20px;
}

.loginTitle {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 27px;
    line-height: 32px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #F2F2F2;
    margin-top: 20px;
    text-shadow: 1px 1px 2px black;
}

.loginGirisYap {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 22px;
    line-height: 30px;
    color: #F2F2F2;
}

.loginFormParameterTitle {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: #BDBDBD;
    text-shadow: 1px 1px 2px black;
}

.loginFormContainer {
    width: 525px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.loginInputUserName {
    width: 100%;
    height: 48px;
    background: #F2F2F2;
    border: 1px solid #E0E0E0;
    box-sizing: border-box;
    border-radius: 4px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #4F4F4F;
    padding-left: 10px;
}

.loginIletisim {
    text-align: left;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #ffffff;
    text-shadow: 1px 1px 2px black;
}
.iletisimLink:hover{
    color: #aeceff;
}
.iletisimLink{
    color: #ffffff;
}
.siparisIdeaMain {
  padding: 10px;
}

.siparisIdeaMiddleRow {
  padding-top: 12px;
  padding-bottom: 12px;
  display: flex;
  justify-content: space-between;
}

.siparisIdeaMainInfo {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.siparisIdeaImage {
  width: 360px;
  height: 360px;
  border: 2px dashed #E0E0E0;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
}

.siparisIdeaMainHeaders {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #4F4F4F;
}

.siparisIdeaCheckbox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 300px;
}

.siparisIdeaCheckboxFont {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #828282;
}

.siparisIdeaPhotoUpload {
  height: 100px;
  width: auto;
}

.siparisIdeaPhotoUploadDescriptionTitle {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  color: #4F4F4F;
  padding-top: 10px;
}

.siparisIdeaPhotoUploadDescription {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #4F4F4F;
  padding-top: 10px;
}

.siparisIdeaImagePhoto {
  width: 358px;
  height: 358px;
}

.modalLoader {
  padding-top: 150px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
}

.siparisIdeaAttachment {
  /* width: 242px;
  height: 62px; */

  padding: 8px 14px;
  border: 2px dashed #E0E0E0;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.siparisIdeaAttachmentDosya {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #4F4F4F;
}

.siparisIdeaAttachmentInfo {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: #4F4F4F;
}

.siparisIdeaAttachmentRightContainer {
  margin-left: 10px;
  display: flex;
  flex-direction: column;
}

.siparisIdeaAttachmentFileName {
  width: 380px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  text-align: left;
  color: #4F4F4F;
}

.siparisIdeaSubmitButtons {
  display: flex;
  justify-content: flex-end;
  /* margin-top: 24px; */
  margin-top: 100px;
}

.siparisIdeaTeamMembers {
  padding: 8px 14px;
  border: 2px dashed #E0E0E0;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.tag-item {
  background-color: #e6e6e6;
  display: inline-block;
  font-size: 14px;
  border-radius: 30px;
  height: 30px;
  padding: 0 4px 0 1rem;
  display: inline-flex;
  align-items: center;
  margin: 0 0.3rem 0.3rem 0;
}

.tag-item>.button {
  background-color: white;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  font: inherit;
  margin-left: 10px;
  font-weight: bold;
  padding: 0;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
}

.tag-item>.button:hover {
  color: white;
  background-color: #4F4F4F;
}

.RichTextEditorContainer {
  margin-top: 24px;
}

.SiparisIdea_SiparisTitle {
  margin-top: 8px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: #4F4F4F;
}

.editorWrapper {
  height: 250px;
}

.editorBody {
  padding: 5px;
  border: 1px solid #E0E0E0;
  background-color: rgba(221, 221, 221, 0.4);
}
body {
    font-family: "Public Sans", sans-serif;
}

.header-bar {
    background-color: #292929;
}

.container--narrow {
    max-width: 732px;
}

.header-search-icon {
    position: relative;
    top: 3px;
}

.header-chat-icon {
    cursor: pointer;
    position: relative;
    top: 3px;
}

.chat-count-badge {
    text-align: center;
    position: absolute;
    top: 2px;
    left: 0px;
    width: 16px;
    font-size: 0.6rem;
    font-weight: bold;
}

.avatar-small {
    width: 32px;
    height: 32px;
    border-radius: 16px;
    margin-right: 5px;
    position: relative;
    top: -3px;
}

.avatar-tiny {
    width: 24px;
    height: 24px;
    border-radius: 12px;
    margin-right: 4px;
    position: relative;
    top: -1px;
}

.form-control-title {
    font-size: 2rem;
    font-weight: 500;
}

.body-content {
    font-size: 1.2rem;
    line-height: 1.75;
    color: #292929;
}

.body-content p,
.body-content ul,
.body-content ol {
    margin-bottom: 1.75rem;
}

.input-dark {
    background-color: #444;
    border-color: transparent;
    color: #ffffff;
}

.input-dark:focus {
    color: #ffffff;
    background-color: #555;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.input-dark::-webkit-input-placeholder {
    color: #888;
}

.input-dark::-moz-placeholder {
    color: #888;
}

.input-dark:-ms-input-placeholder {
    color: #888;
}

.input-dark:-moz-placeholder {
    color: #888;
}

@media (min-width: 768px) {
    .input-dark {
        width: auto;
    }
}

.display-3 {
    font-size: 4.2rem;
}

@media (max-width: 1199px) {
    .display-3 {
        font-size: 3.4rem;
    }
}

@media (max-width: 768px) {
    .display-3 {
        font-size: 2.5rem;
    }
}

.form-group {
    position: relative;
}

.liveValidateMessage {
    top: -6px;
    position: absolute;
    z-index: 1;
    padding-top: 6px;
    padding-bottom: 16px;
    padding-left: 0.8rem;
    padding-right: 0.8rem;
}

.errorMessage {
    /* background-color: #faacac;
    position: absolute;
    color: #8a1010;
    border: 1px solid #ea0000 !important; */
    background-color: #434343;
    position: absolute;
    color: #ffffff;
    border: 1px solid #1a1a1a !important;
    box-sizing: border-box !important;
    border-radius: 10px 10px 0px 0px !important;
    height: 30px;
    padding: 5px 20px 20px 20px;
    top: -30px;
    width: -webkit-max-content;
    width: max-content;
}

.errorMessageSpics {
    /* background-color: #faacac;
    position: absolute;
    color: #8a1010;
    border: 1px solid #ea0000 !important; */
    background-color: #434343;
    position: absolute;
    color: #ffffff;
    border: 1px solid #1a1a1a !important;
    box-sizing: border-box !important;
    border-radius: 10px 10px 0px 0px !important;
    height: 30px;
    padding: 5px 20px 20px 20px;
    top: -30px;
    width: -webkit-max-content;
    width: max-content;
    margin-left: 6px;
}


.liveValidateMessage--visible {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
}

.liveValidateMessage-enter {
    opacity: 0;
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
}

.liveValidateMessage-enter-active {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
    transition: 0.33s opacity ease-in-out, 0.33s -webkit-transform ease-in-out;
    transition: 0.33s opacity ease-in-out, 0.33s transform ease-in-out;
    transition: 0.33s opacity ease-in-out, 0.33s transform ease-in-out, 0.33s -webkit-transform ease-in-out;
}

.liveValidateMessage-exit {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
}

.liveValidateMessage-exit-active {
    opacity: 0;
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
    transition: 0.33s opacity ease-in-out, 0.33s -webkit-transform ease-in-out;
    transition: 0.33s opacity ease-in-out, 0.33s transform ease-in-out;
    transition: 0.33s opacity ease-in-out, 0.33s transform ease-in-out, 0.33s -webkit-transform ease-in-out;
}

.form-group input,
.form-group textarea {
    position: relative;
    z-index: 2;
}

textarea.tall-textarea {
    height: 160px;
}

@media (min-width: 768px) {
    textarea.tall-textarea {
        height: 320px;
    }
}

.delete-post-button {
    cursor: pointer;
    background: none;
    border: none;
    padding: 0;
    margin: 0;
}

/* Search Overaly */
.search-overlay {
    display: flex;
    flex-direction: column;
    position: fixed;
    z-index: 9000;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(215, 215, 215, 0.911);
}

.search-overlay-enter {
    opacity: 0;
    -webkit-transform: scale(1.3);
            transform: scale(1.3);
}

.search-overlay-enter-active {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
    transition: 0.33s visibility ease-in-out, 0.33s opacity ease-in-out, 0.33s -webkit-transform ease-in-out;
    transition: 0.33s visibility ease-in-out, 0.33s opacity ease-in-out, 0.33s transform ease-in-out;
    transition: 0.33s visibility ease-in-out, 0.33s opacity ease-in-out, 0.33s transform ease-in-out, 0.33s -webkit-transform ease-in-out;
}

.search-overlay-exit {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
}

.search-overlay-exit-active {
    opacity: 0;
    -webkit-transform: scale(1.3);
            transform: scale(1.3);
    transition: 0.33s visibility ease-in-out, 0.33s opacity ease-in-out, 0.33s -webkit-transform ease-in-out;
    transition: 0.33s visibility ease-in-out, 0.33s opacity ease-in-out, 0.33s transform ease-in-out;
    transition: 0.33s visibility ease-in-out, 0.33s opacity ease-in-out, 0.33s transform ease-in-out, 0.33s -webkit-transform ease-in-out;
}

.search-overlay-icon {
    color: #007bff;
    font-size: 1.4rem;
    margin: 0;
    margin-right: 10px;
}

.live-search-field {
    background-color: transparent;
    border: none;
    font-size: 1.1rem;
    outline: none;
    flex: 1 1;
    color: #007bff;
}

.live-search-results {
    opacity: 0;
    transition: all 0.3s ease-out;
    -webkit-transform: scale(1.07);
            transform: scale(1.07);
    visibility: hidden;
}

.live-search-results--visible {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
    visibility: visible;
}

.search-overlay-top {
    background-color: #fff;
    /* background-color: rgba(0, 0, 0, .79); */
}

.search-overlay-top .container {
    position: relative;
    display: flex;
    align-items: center;
    padding-top: 15px;
    padding-bottom: 15px;
}

.search-overlay-bottom {
    overflow: auto;
}

.close-live-search {
    font-size: 1.5rem;
    cursor: pointer;
    opacity: 0.75;
    line-height: 1;
    color: #292929;
}

@media (min-width: 700px) {
    .live-search-field {
        font-size: 2.5rem;
    }

    .close-live-search {
        font-size: 3rem;
    }

    .search-overlay-icon {
        font-size: 3rem;
    }
}

.close-live-search:hover {
    opacity: 1;
}

@-webkit-keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.circle-loader {
    opacity: 0;
    transition: opacity 0.45s ease-out, visibility 0.45s ease-out;
    visibility: hidden;
    position: absolute;
    left: 50%;
    box-sizing: border-box;
    width: 65px;
    height: 65px;
    border-radius: 100%;
    border: 10px solid rgba(73, 80, 87, 0.2);
    border-top-color: #495057;
    will-change: -webkit-transform, transform;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-animation: spin 1s infinite linear;
    animation: spin 1s infinite linear;
}

.circle-loader--visible {
    visibility: visible;
    opacity: 1;
}

/* End Search Overlay */

/* Chat */
.chat-wrapper {
    position: fixed;
    z-index: 5;
    bottom: 0;
    right: 20px;
    width: 290px;
    height: 350px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    opacity: 0;
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
    transition: 0.33s opacity ease-in-out, 0.33s -webkit-transform ease-in-out;
    transition: 0.33s opacity ease-in-out, 0.33s transform ease-in-out;
    transition: 0.33s opacity ease-in-out, 0.33s transform ease-in-out, 0.33s -webkit-transform ease-in-out;
}

.chat-wrapper--is-visible {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
}

.chat-title-bar {
    background-color: #292929;
    color: #fff;
    padding: 4px 7px;
    display: flex;
    justify-content: space-between;
}

.chat-title-bar-close {
    opacity: 0.7;
    cursor: pointer;
}

.chat-title-bar-close:hover {
    opacity: 1;
}

.chat-log {
    padding: 8px;
    flex: 1 1;
    overflow: auto;
}

.chat-self,
.chat-other {
    font-size: 0.75rem;
    display: flex;
    align-items: center;
    margin-bottom: 7px;
}

.chat-self {
    padding-left: 25%;
}

.chat-self .chat-avatar {
    margin-left: 6px;
}

.chat-self .chat-message {
    flex: 1 1;
    display: flex;
    justify-content: flex-end;
}

.chat-self .chat-message-inner {
    text-align: right;
    padding: 4px 7px;
    border-radius: 12px;
    background-color: #007bff;
    color: #fff;
}

.chat-other {
    padding-right: 25%;
}

.chat-other .chat-avatar {
    margin-right: 6px;
}

.chat-other .chat-message {
    flex: 1 1;
    display: flex;
    justify-content: flex-start;
}

.chat-other .chat-message-inner {
    padding: 4px 7px;
    border-radius: 12px;
    background-color: #f1f0f0;
}

.chat-message a {
    color: #212529;
}

.chat-field {
    width: 100%;
    box-sizing: border-box;
    padding: 10px 7px;
    border: none;
    outline: none;
    font-size: 0.75rem;
}

.floating-alert {
    display: none;
    position: absolute;
    z-index: 999;
    top: 38px;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    -webkit-animation: floatingAlert ease-in 5s forwards;
    animation: floatingAlert ease-in 5s forwards;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
}

.floating-alerts .floating-alert:last-of-type {
    display: block;
}

@keyframes floatingAlert {
    0% {
        opacity: 0;
        visibility: hidden;
        -webkit-transform: translateX(-50%) scale(1.2);
                transform: translateX(-50%) scale(1.2);
    }

    9% {
        opacity: 1;
        visibility: visible;
        -webkit-transform: translateX(-50%) scale(1);
                transform: translateX(-50%) scale(1);
    }

    91% {
        opacity: 1;
        visibility: visible;
        -webkit-transform: translateX(-50%) scale(1);
                transform: translateX(-50%) scale(1);
    }

    100% {
        opacity: 0;
        visibility: hidden;
        -webkit-transform: translateX(-50%) scale(1.2);
                transform: translateX(-50%) scale(1.2);
    }
}

@-webkit-keyframes floatingAlert {
    0% {
        opacity: 0;
        visibility: hidden;
        -webkit-transform: translateX(-50%) scale(1.2);
                transform: translateX(-50%) scale(1.2);
    }

    9% {
        opacity: 1;
        visibility: visible;
        -webkit-transform: translateX(-50%) scale(1);
                transform: translateX(-50%) scale(1);
    }

    91% {
        opacity: 1;
        visibility: visible;
        -webkit-transform: translateX(-50%) scale(1);
                transform: translateX(-50%) scale(1);
    }

    100% {
        opacity: 0;
        visibility: hidden;
        -webkit-transform: translateX(-50%) scale(1.2);
                transform: translateX(-50%) scale(1.2);
    }
}

.small-header-avatar {
    width: 32px;
    height: 32px;
    border-radius: 16px;
}

.custom-tooltip {
    padding: 8px 10px !important;
}

/* Dots Loading Animation */
.dots-loading {
    margin: 0 auto;
    text-align: center;
}

.dots-loading::before,
.dots-loading::after {
    content: " ";
}

.dots-loading div,
.dots-loading::before,
.dots-loading::after {
    margin: 35px 5px;
    display: inline-block;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: #c4c4c4;
    opacity: 0;
}

.dots-loading::before {
    -webkit-animation: opacitychange 1s ease-in-out infinite;
    animation: opacitychange 1s ease-in-out infinite;
}

.dots-loading div {
    -webkit-animation: opacitychange 1s ease-in-out 0.33s infinite;
    animation: opacitychange 1s ease-in-out 0.33s infinite;
    -webkit-animation-fill-mode: infinite;
    animation-fill-mode: infinite;
}

.dots-loading::after {
    -webkit-animation: opacitychange 1s ease-in-out 0.66s infinite;
    animation: opacitychange 1s ease-in-out 0.66s infinite;
    -webkit-animation-fill-mode: infinite;
    animation-fill-mode: infinite;
}

@keyframes opacitychange {

    0%,
    100% {
        opacity: 0;
    }

    60% {
        opacity: 1;
    }
}

@-webkit-keyframes opacitychange {

    0%,
    100% {
        opacity: 0;
    }

    60% {
        opacity: 1;
    }
}
/* The Modal (background) */
.modalAlert {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 100; /* Sit on top */
    padding-top: 100px; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
  }
  
  /* Modal Content */
  .modalAlert-content {
    position: relative;
    background-color: #fefefe;
    margin: auto;
    padding: 0;
    border: 1px solid #888;
    width: 80%;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
    -webkit-animation-name: animatetop;
    -webkit-animation-duration: 0.4s;
    animation-name: animatetop;
    animation-duration: 0.4s
  }
  
  /* Add Animation */
  @-webkit-keyframes animatetop {
    from {top:-300px; opacity:0} 
    to {top:0; opacity:1}
  }
  
  @keyframes animatetop {
    from {top:-300px; opacity:0}
    to {top:0; opacity:1}
  }
  
  /* The Close Button */
  .closemodalAlert {
    color: white;
    float: right;
    font-size: 28px;
    font-weight: bold;
  }
  
  .closemodalAlert:hover,
  .closemodalAlert:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
  }
  
  .modalAlert-header {
    padding: 2px 16px;
    background-color: #5cb85c;
    color: white;
  }
  
  .modalAlert-body {padding: 2px 16px;}
  
  .modalAlert-footer {
    padding: 2px 16px;
    background-color: #5cb85c;
    color: white;
  }
.siparisIdeaItemContainer {
    margin-right: 8px;
    margin-left: 8px;
    margin-top: 20px;
    width: 435px;
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.14);
    /* border-radius: 4px; */
    display: flex;
    cursor: pointer;
}

.siparisIdeaPhotoContainer {
    width: 200px;
    height: 200px;
}

.siparisIdeaSiparisInfo {
    width: 100%;
    border-radius: 10px;
    padding: 20px;
    margin-top: 20px;
}

.siparisIdeaItemWindow {
    width: 435px;
    display: flex;
    background-color: white;
    border-radius: 10px;
    padding: 5px;
}

.siparisIdeaInfoContainer {
    padding: 16px;
    width: 225px;
    display: flex;
    justify-content: space-between;
    align-content: space-around;
    flex-wrap: nowrap;
    flex-direction: column;
}

.siparisIdeaPhoto {
    width: 200px;
    height: 200px;
}

.siparisIdeaHeaderTitleContainer {
    position: absolute;
    display: flex;
    flex-direction: column;
    margin-left: 50px;
    bottom: 30px;
}

.siparisIdeaBannerTitle {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 68px;
    align-items: center;
    color: #FFF;
    /* text-shadow: 0px 4px 8px rgba(0, 0, 0, 0.35); */
}

.siparisIdeaBannerText {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 36px;
    color: #FFF;
    /* text-shadow: 0px 4px 8px rgba(0, 0, 0, 0.35); */
}

.siparisIdeaTitle {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 30px;
    color: #4F4F4F;
}

.siparisIdeaDescription {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #828282;
    word-wrap: break-word;
}

.siparisIdeaActions {
    display: flex;
    justify-content: space-between;
}

.siparisIdeaRightSideContainer {
    margin-top: 20px;
}

.siparisIdeaSideBar {
    width: 250px;
    padding: 16px;
    background-color: white;
}


.siparisStatusText {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
    color: #4F4F4F;
}

.siparisIdeaStepsLine {
    margin-top: 10px;
    width: 100%;
    height: 1px;
    background: #E0E0E0;
}

.siparisSideBarStepsText {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: #BDBDBD;
}

.siparisSideBarIdeaDescription {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #828282;
    word-break: break-word;
}

.currentStepColor {
    color: #4F4F4F;
}

.ideaSortOrderDashBoardContainer {
    margin-right: 50px;
    display: flex;
    flex-direction: row-reverse;
}

.ideaSortOrderDashBoard {
    margin-right: 30px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: #ffffff;
}

.ideaSortOrderDashBoardMenuItems {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #FFF;
}
.siparisIdeaDetailContainer {
  padding: 10px;
}


.siparisIdeaDetailAttachmentRightContainer {
  margin-left: 10px;
  display: flex;
  flex-direction: column;
}

.siparisIdeaDetailAttachmentFileName {
  width: 380px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: left;
  color: #828282;
}

.siparisIdeaDetailPhoto {
  width: 360px;
  height: 360px;
}

.siparisIdeaDetailRightSideBar {
  margin-left: 25px;
  width: 60%;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  flex-direction: column;
}

.siparisDetailSiparisTitle {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: #828282;
}

.siparisDetailIdeaTitle {
  margin-top: 8px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 44px;
  color: #333333;
}

.siparisDetailIdeaOwner {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  color: #5b5b5b;
}

.siparisIdeaDetailTeamMembers {
  margin-left: 10px;
  padding: 8px 0px;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.siparisIdeaDetail-tag-item {
  background-color: #e6e6e6;
  font-size: 14px;
  border-radius: 20px;
  height: 30px;
  padding: 0 15px 0 15px;
  display: inline-flex;
  align-items: center;
  margin: 0.4rem 0.3rem 0 0;
}

.siparisIdeaMiddleRow {
  padding-top: 12px;
  padding-bottom: 12px;
  display: flex;
  justify-content: space-between;
}

.siparisIdeaMainInfo {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.siparisIdeaDetailImage {
  width: 360px;
  height: 360px;
  border: 2px dashed #E0E0E0;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
}

.siparisIdeaDetailCommentsTitle {
  margin-top: 40px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: #4F4F4F;
}

.siparisIdeaDetailCommentLine {
  margin-top: 24px;
  width: 100%;
  height: 2px;
  background: #E0E0E0;
}

.siparisIdeaDetailCommentSeperatorLine {
  margin-top: 24px;
  width: 100%;
  height: 1px;
  background: #E0E0E0;
}

.siparisDetailCommentBox {
  width: 100%;
  height: 200px;
  background: #F2F2F2;
  border: 1px solid #E0E0E0;
  box-sizing: border-box;
  border-radius: 4px;
}

.IdeaCommentUserCircle {
  background-color: #BDBDBD;
  width: 30px;
  height: 30px;
  border-radius: 20px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}

.siparisIdeaDetailSubmitButton {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.siparisIdeaDetailCommentUserName {
  margin-left: 8px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #4F4F4F;
}

.siparisIdeaDetailComments {
  margin-top: 8px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #4F4F4F;
}

.ideaLikeCircle {
  position: absolute;
  right: -120px;
  background-color: white;
  width: 80px;
  height: 80px;
  border-radius: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.BacgroundBlue {
  background-color: #32C5FF;
}

.ideaLikeCircle:hover {
  background-color: rgb(172, 231, 255);
}

.siparisIdeaDetailActions {
  display: flex;
  justify-content: space-between;
}

.siparisDetailCountIcons {
  Width: 13.5px;
  Height: 18px;
}

.siparisIdeaCounts {
  margin-left: 8px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: #4F4F4F;
}
/* Tooltip container */
.tooltip {
    position: relative;
    /* display: inline-block; */
  }
  
  /* Tooltip text */
  .tooltip .tooltiptext {
    visibility: hidden;
    width: 300px;
    background-color: #555;
    color: #fff;
    text-align:center;
    padding: 15px 15px;
    border-radius: 6px;
  
    /* Position the tooltip text */
    position: absolute;
    z-index: 11;
    bottom: 125%;
    /* left: 50%; */
    /* margin-left: -60px; */
  
    /* Fade in tooltip */
    opacity: 0;
    transition: opacity 0.3s;
  }
  
  /* Tooltip arrow */
  .tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    /* top: 100%;
    left: 50%; */
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
  }
  
  /* Show the tooltip text when you mouse over the tooltip container */
  .tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }
.shareFormMain {
    padding: 10px;
  }

  .shareFormMiddleRow {
    padding-top: 12px;
    padding-bottom: 12px;
    display: flex;
    justify-content: space-between;
  }
  
  .shareFormMainInfo {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .shareFormMainHeaders {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: #4F4F4F;
  }
  
  .shareForm_SiparisTitle {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    color: #4F4F4F;
  }
  .shareFormShareMainHeaders{
    margin-top: 20px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    color: #4F4F4F;
  }

  .shareFormTeamMembers {
    margin-top: 10px;
    padding: 8px 14px;
    border: 2px dashed #E0E0E0;
    box-sizing: border-box;
    border-radius: 4px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: left;
  }

  .shareFormSubmitButtons {
    display: flex;
    justify-content: flex-end;
    margin-top: 24px;
  }
.adminSiparisContainer{
    width: 100%;
    min-height: 100vh;
    background-color: whitesmoke;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;

}

.adminBannerContainer{
    width: 100%;
    height: 100px;
    background-color: whitesmoke;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.adminSiparisBannerTitle {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    margin-left: 20px;
    /* identical to box height, or 133% */

    display: flex;
    align-items: center;
    text-transform: uppercase;
    color: #333333;

}

.adminSiparisBannerDescription{

       
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 22px;
    line-height: 30px;
    margin-left: 20px; 
    color: #828282;
}

.adminBannerContainerText{
    width: 59px;
    height: 24px;
    
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */
    
    
    /* Grays/Gray 6 */
    
    color: #F2F2F2;

}
.adminContainerScroll{
    overflow-y: scroll;
    cursor: default;
    height: 500px;
}
.AdminsiparisItemContainer {
    width: 100%;
    background: #FFFFFF;
    display: flex;
    border-left: 1px solid  #E0E0E0;
}

.adminListItemBox{
    padding: 10px 15px;
    /* border:1px solid  #E0E0E0; */
    border-bottom: 1px solid  #E0E0E0;
    border-right: 1px solid  #E0E0E0;
}

.adminListItemText {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #4F4F4F;
}

/* .adminSiparisEditIconDiv {
    width: 36px;
    padding: 5px;
    border:1px solid  #E0E0E0;
    display: flex;
    justify-content: center;
    align-items: center;
  } */
.adminSiparisIconDiv {
    width: 36px;
    padding: 5px;
    /* border:1px solid  #E0E0E0; */
    border-bottom: 1px solid  #E0E0E0;
    border-right: 1px solid  #E0E0E0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
/* 
  .adminSiparisIconDiv svg:hover{
    width: 14px;
  }

  .adminSiparisIconDiv img:hover{
    width: 10px;
  } */
.StepTitleContainerBox {
    width: 100%;
    height: 60px;
    background: #F2F2F2;
    border-radius: 4px;
    border: 1px solid #dadada;
    display: flex;
    align-items: center;
    justify-content: center;
}

.StepTitleContainer {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-around;
    margin-left: 20px;
    margin-right: 20px;
}

.SetStepIdeaCurrentStepTitle {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 26px;
    color: #4F4F4F;
    padding-right: 20px;
    white-space: nowrap;
}

.SetStepIdeaTitleProgress {
    height: 8px;
    background: #828282;
    border-radius: 4px;
    width: 50%;
}

.SetStepIdeaNextStepTitle {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 26px;
    color: #BDBDBD;
    padding-left: 20px;
    white-space: nowrap;
}

.SetStepIdeaHeaderContainer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
    align-items: center;
}

.SetStepIdeaHeaderTitle {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    color: #4F4F4F;
}

.SetStepIdeaHeaderSortTitle {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: #606060;
    margin-right: 5px;
}

.downIcon {
    color: #828282;
}

.downIcon:hover {
    color: #4a4a4a;
}

.dropDownList {
    background: #F2F2F2;
    border-radius: 4px;
    border: 1px solid #dadada;
    display: flex;
    align-items: center;
    padding: 10px 15px 10px 15px;
    cursor: pointer;
}

.dropDownList:hover {
    background: #dadada;
}

.SetStepIdeaSelectContainer {
    box-sizing: border-box;
    width: 100%;
    height: 60px;
    border: 2px solid #F2F2F2;
    border-radius: 4px;
    margin-top: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
}

.SetStepIdeaSelectIdeaTitle {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    color: #4F4F4F;
}

.SetStepIdeaSelectDate {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: #828282;
    margin-right: 20px;
}

.ideaSortOrder {
    margin-right: 30px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: #828282;
}
.tedarikci-email {
    margin-top: 10px;
    padding: 8px 14px;
    border: 2px dashed #E0E0E0;
    box-sizing: border-box;
    border-radius: 4px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.tag-item {
    background-color: #e6e6e6;
    display: inline-block;
    font-size: 14px;
    border-radius: 30px;
    height: 30px;
    padding: 0 4px 0 1rem;
    display: inline-flex;
    align-items: center;
    margin: 0 0.3rem 0.3rem 0;
}

.tag-item>.button {
    background-color: white;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    border: none;
    cursor: pointer;
    font: inherit;
    margin-left: 10px;
    font-weight: bold;
    padding: 0;
    line-height: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;
}

.tag-item>.button:hover {
    color: white;
    background-color: #4F4F4F;
}


.tedarikcilerBodyContainer {
    background-color: #ffffff;
    width: 100%;
    padding: 20px;
}



.tedarikciAttachment {
    /* width: 242px;
    height: 62px; */
  
    padding: 8px 14px;
    border: 2px dashed #E0E0E0;
    box-sizing: border-box;
    border-radius: 4px;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  
  .tedarikciAttachmentDosya {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #4F4F4F;
  }
  
  .tedarikciAttachmentInfo {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    color: #4F4F4F;
  }
  
  .tedarikciAttachmentRightContainer {
    margin-left: 10px;
    display: flex;
    flex-direction: column;
  }
  
  .tedarikciAttachmentFileName {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    text-align: left;
    color: #4F4F4F;
  }
  
.paramBodyContainer {
    background-color: #ffffff;
    width: 100%;
    padding: 20px;
}

.parametreSecimTable table th {
    /* border: 1px solid var(--color-light); */
    border-bottom: 1px solid var(--color-light);
    color: var(--color-dark-variant);
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    padding: 0.8rem;
    left: 0;
    margin: 0px;
}

.parametreSecimTable table tbody td {
    height: 2.8rem;
    padding: 0.8rem;
    /* border: 1px solid var(--color-light); */
    border-bottom: 1px solid var(--color-light);
    color: var(--color-dark-variant);
}

.parametreSecimTable table tr td:first-child {
    text-align: left;
}

.formTable table th {
    color: var(--color-dark-variant);
    font-family: 'Roboto', sans-serif;
    border-bottom: 1px solid var(--color-light);
    font-weight: 500;
    padding: 0.2rem;
    left: 0;
    margin: 0px;
}

.formTable table tbody td {
    height: 2.8rem;
    padding: 0.2rem;
    color: var(--color-dark-variant);
}

.formTable table tr td:first-child {
    text-align: left;
}
/*ROOT VARIABLES*/
:root {
  --color-primary: #32C5FF;
  --color-danger: #FF7782;
  --color-success: #41F1B6;
  --color-warning: #FFBB55;
  --color-white: #FFF;
  --color-info-dark: #7D8DA1;
  --color-info-light: #DCE1EB;
  --color-dark: #363949;
  --color-light: rgba(132, 139, 200, 0.18);
  --color-primarty-variant: #111E88;
  --color-dark-variant: #677483;
  --color-background: #F6F6F9;
  --color-box-shadow-hover: #bfc3ca;

  --card-border-radius: 2rem;
  --border-radius-1: 0.4rem;
  --border-radius-2: 0.8rem;
  --border-radius-3: 1.2rem;

  --card-padding: 1.8rem;
  --padding-1: 1.2rem;

  --box-shadow: 0 2rem 3rem var(--color-light);
  --box-shadow-hover: 0 2rem 3rem var(--color-box-shadow-hover);
}

/*END ROOT VARIABLES*/

body {
  background-color: rgb(233, 230, 230) !important;
  /* font-family: 'Montserrat', sans-serif; */
  font-family: 'Roboto', sans-serif;
  color: #677483;
  color: var(--color-dark-variant);
  font-weight: 500;
}

.MainContainer {
  width: 1200px !important;
}

.ui.inverted.top.fixed.menu {
  background-color: white;
  /* background-image: linear-gradient(135deg, rgb(0, 0, 0) 0%, rgb(48, 48, 48) 69%, rgb(77, 77, 77) 89%) !important; */
}

.react-calendar {
  width: 100%;
  border: none;
  box-shadow: 0 1px 2px 0 rgba(34, 36, 38, .15);
}


.react-datepicker-wrapper {
  width: 100%;
}

/*home page styles*/

.masthead {
  display: flex;
  align-items: center;
  background-image: linear-gradient(135deg,
      rgb(0, 0, 0) 0%, rgb(48, 48, 48) 69%, rgb(77, 77, 77) 89%) !important;
  height: 100vh;
}

.ui.inverted.menu {
  font-family: 'Montserrat', sans-serif !important;
  color: black;
  height: 60px;
}

.masthead .ui.menu .ui.button,
.ui.menu a.ui.inverted.button {
  margin-left: 0.5em;
  color: black;
}

.ui.inverted.menu .item {
  color: rgb(0, 0, 0) !important;
}

.ui.inverted.menu .item:hover {
  background-color: rgb(212, 212, 212) !important;
  color: rgb(0, 0, 0) !important;
}

.ui.inverted.menu .active.item:hover {
  color: rgb(0, 0, 0) !important;
}

.ui.inverted.menu .active.item {
  background: rgba(206, 206, 206, 0.15);
  color: rgb(0, 0, 0) !important;
}

.masthead h1.ui.header {
  font-size: 4em;
  font-weight: normal;
}

.masthead h2 {
  font-size: 1.7em;
  font-weight: normal;
}

/*end home page styles*/

.ui.table tr {
  border-top: 1px solid rgba(10, 87, 175, 0.1);
  background-color: rgb(231, 238, 248);
}

.floathright {
  float: right;
  margin: 0 0 0 .5rem;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -1rem;
  margin-right: -1rem;
}

.column {
  width: 50%;
  padding-left: 1rem;
  padding-right: 1rem;
}

.textInput {
  background: #F2F2F2 !important;
  border: 1px solid #E0E0E0 !important;
  box-sizing: border-box !important;
  border-radius: 4px !important;
}

.green {
  padding: 8px 16px;
  background: #00667E;
  border-radius: 4px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  text-align: right;
  color: #F2F2F2;

}

.button {
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  font-size: 14px;
  cursor: pointer;
  display: block;
  background: #32C5FF;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  /* width: 85px; */
}

.button:hover {
  background: #2391bd;
}

.button:not(:last-child) {
  border-bottom: none;
  /* Prevent double borders */
}

.samlButton {
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  font-size: 14px;
  cursor: pointer;
  display: block;
  background: #2da44e;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  width: 300px;
}

.samlButton:hover {
  background: #207739;
}


.blue {
  background: #00667E;
}

.blue:hover {
  background: #0594b4;
}

/* Onay Butonu */
.confirm-button {
  background: #70b853;
  /* Yeşil renk örneği */
}

.confirm-button:hover {
  background: #84c769;
  /* Hover durumunda farklı bir yeşil renk örneği */
}

/* Onay Butonu */
.retrofit-button {
  background: #b853ac;
  /* Yeşil renk örneği */
}

.retrofit-button:hover {
  background: #d05fc3;
  /* Hover durumunda farklı bir yeşil renk örneği */
}

/* Ret Butonu */
.reject-button {
  background: #EB5160;
  /* Kırmızı renk örneği */
}

.reject-button:hover {
  background: #ff4d4d;
  /* Hover durumunda farklı bir kırmızı renk örneği */
}

/* Geri Gönder Butonu */
.return-button {
  background: #F58A07;
  /* Turuncu renk örneği */
}

.return-button:hover {
  background: #f3a94e;
  /* Hover durumunda farklı bir turuncu renk örneği */
}

/* Kapat Butonu */
.close-button {
  background: #FF5733;
  /* Kırmızı renk örneği */
}

.close-button:hover {
  background: #ff814d;
  /* Hover durumunda farklı bir kırmızı renk örneği */
}

/* Tarihçe Butonu */
.history-button {
  background: #086788;
  /* Mavi renk örneği */
}

.history-button:hover {
  background: #0a87b1;
  /* Hover durumunda farklı bir mavi renk örneği */
}

/* Koyu Gri Buton */
.dark-gray-button {
  background: #333 !important;
  /* Koyu gri renk örneği */
}

.dark-gray-button:hover {
  background: #555 !important;
  /* Hover durumunda farklı bir koyu gri renk örneği */
}

.air-blue-button {
  background: #5BC0EB !important;
  /* Koyu gri renk örneği */
}

.air-blue-button:hover {
  background: #82a9c2 !important;
  /* Hover durumunda farklı bir koyu gri renk örneği */
}

.no-hoverBtn:hover {
  pointer-events: none;
}



.buttonPaylas {
  padding: 8px 18px;
  border: 2px solid #32C5FF;
  box-sizing: border-box;
  border-radius: 4px;
  color: #32C5FF;
  background-color: white;

  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  cursor: pointer;
}

.mr20 {
  margin-right: 20px;
}

.buttonPaylas:hover {
  border: 2px solid #0594b4;
  color: #0594b4;
}

.LinkBtn {
  background-color: white;
  border: none;
  color: #32C5FF;
}

input[type="text"] {
  background: #F2F2F2;
  border: 1px solid #E0E0E0 !important;
  box-sizing: border-box !important;
  border-radius: 4px !important;
  text-align: 'center';
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #4F4F4F;
  padding: 5px;
}

input[type="text"].has-error {
  border-color: rgba(153, 35, 14, 0.815) !important;
  background-color: rgb(248, 239, 238) !important;
}

input[type="text"],
input[type="password"],
textarea,
select {
  outline: none;
}

.error {
  margin: 0;
  font-size: 90%;
  color: tomato;
}

textarea {
  background: #F2F2F2 !important;
  border: 1px solid #E0E0E0 !important;
  box-sizing: border-box !important;
  border-radius: 4px !important;

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #4F4F4F;
  padding: 5px;
}

/* background: */
/* progress::-webkit-progress-bar {background-color: white; width: 100%;} */
/* progress {background-color: white;} */

/* value: */
/* progress::-webkit-progress-value {background-color: #32C5FF !important;} */
/* progress::-moz-progress-bar {background-color: #32C5FF !important;} */
/* progress {color: #32C5FF;} */

/* progress {
  border: 1px solid #E0E0E0;
  box-sizing: border-box;
  border-radius: 4px;
  width: 100%;
height: 8px;
} */


.progress {
  margin: auto;
  padding: 1px;
  width: 100%;
  max-width: 500px;
  background: white;
  border: 1px solid #E0E0E0;
  border-radius: 20px;
  height: 12px;
}

.progress .progress__bar {
  height: 100%;
  width: 5%;
  border-radius: 4px;
  background: #32C5FF;
}

.cursorPointer {
  cursor: pointer;
}

.overlay {
  z-index: 50000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.5;
  /* filter: alpha(opacity=10); */
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

.overlayText {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 32px;
  display: flex;
  align-items: center;
  color: #F2F2F2;
  margin-top: 20px;
  text-shadow: 1px 1px 2px black;
}

.imgDisabled {
  filter: gray;
  /* IE6-9 */
  -webkit-filter: grayscale(1);
  /* Google Chrome, Safari 6+ & Opera 15+ */
  filter: grayscale(1);
  opacity: 0.6;
  /* Microsoft Edge and Firefox 35+ */
}

.cursorAuto {
  cursor: auto;
}

.disabledGrey {
  color: #BDBDBD;
}

.cursorPointer {
  cursor: pointer;
}

.cursorNoPointer {
  cursor: default;
}


/*TABLE*/

table {
  background: #FFF;
  background: var(--color-white);
  padding: 1.8rem;
  padding: var(--card-padding);
  text-align: center;
  border-collapse: separate;
  border-spacing: 0px;
  /* table-layout: fixed; */
  /* display: block;
  overflow: auto;
  padding: 0px;
  white-space: nowrap; */
  width: 100%;
}

.siparisBodyContainer table {
  width: 1200px;
  background: #FFF;
  background: var(--color-white);
  width: auto;
  padding: 1.8rem;
  padding: var(--card-padding);
  text-align: center;
  /* box-shadow: var(--box-shadow); */
  transition: all 300ms ease;
  border-collapse: separate;
  border-spacing: 0px;
  table-layout: fixed;
  display: block;
  overflow: auto;
  padding: 0px;
  white-space: nowrap;
}

/* table:hover {
  box-shadow: var(--box-shadow-hover);
} */

.siparisBodyContainer table th {
  border: 1px solid rgba(132, 139, 200, 0.18);
  border: 1px solid var(--color-light);
  color: white;
  background: #32C5FF;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  padding: 0.8rem;
  left: 0;
  margin: 0px;
}


.siparisBodyContainer table tbody td {
  height: 2.8rem;
  padding: 0.8rem;
  border: 1px solid rgba(132, 139, 200, 0.18);
  border: 1px solid var(--color-light);
  color: #677483;
  color: var(--color-dark-variant);
}

.siparisBodyContainer table tr th:first-child {
  position: relative;
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  z-index: 3;
  color: white;
  background: #32C5FF;
  height: 2.8rem;
  padding: 0.8rem;
  border: 1px solid rgba(132, 139, 200, 0.18);
  border: 1px solid var(--color-light);
}

.siparisBodyContainer table tr td:first-child {
  position: relative;
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  z-index: 3;
  background: white;
  height: 2.8rem;
  padding: 0.8rem;
  border: 1px solid rgba(132, 139, 200, 0.18);
  border: 1px solid var(--color-light);
  color: #677483;
  color: var(--color-dark-variant);
}

.siparisBodyContainer table tr th:nth-child(2) {
  position: relative;
  position: -webkit-sticky;
  position: sticky;
  left: 68px;
  z-index: 3;
  height: 2.8rem;
  padding: 0.8rem;
  border: 1px solid rgba(132, 139, 200, 0.18);
  border: 1px solid var(--color-light);
  color: white;
  background: #32C5FF;
}

.siparisBodyContainer table tr td:nth-child(2) {
  position: relative;
  position: -webkit-sticky;
  position: sticky;
  left: 68px;
  z-index: 3;
  background: white;
  height: 2.8rem;
  padding: 0.8rem;
  border: 1px solid rgba(132, 139, 200, 0.18);
  border: 1px solid var(--color-light);
  color: #677483;
  color: var(--color-dark-variant);
}

.siparisBodyContainer table tr th:nth-child(3) {
  position: relative;
  position: -webkit-sticky;
  position: sticky;
  left: 243px;
  z-index: 3;
  height: 2.8rem;
  padding: 0.8rem;
  border: 1px solid rgba(132, 139, 200, 0.18);
  border: 1px solid var(--color-light);
  color: white;
  background: #32C5FF;
}

.siparisBodyContainer table tr td:nth-child(3) {
  position: relative;
  position: -webkit-sticky;
  position: sticky;
  left: 243px;
  z-index: 3;
  background: white;
  height: 2.8rem;
  padding: 0.8rem;
  border: 1px solid rgba(132, 139, 200, 0.18);
  border: 1px solid var(--color-light);
  color: #677483;
  color: var(--color-dark-variant);
}

/* table tr td:first-child {
  position: relative;
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  z-index: 1;
} */


/* main table tbody tr:last-child td{
  border:none;
} */

/*EOF TABLE*/
/* 
table {
  border-collapse: collapse;
  
} */
/* 
.coll-sticky {
  background: aquamarine;
  position: sticky;
  left: 0px;
}

.tabl-sticky>tfoot>tr>th {
  background: aquamarine;
  position: sticky;
  bottom: 0px;
}

.tabl-sticky>thead>tr>th {
  background: aquamarine;
  position: sticky;
  top: 0px;
}

.tabl-height {
  height: 320px;
  display: block;
  overflow: scroll;
  width: 100%;
}

.tabl-bordered>thead>tr>th,
.tabl-bordered>tfoot>tr>th,
.tabl-bordered>tbody>tr>td {
  border: 1px solid black;
}

.tabl-zebra>tbody>tr:nth-child(odd) {
  background: lightgray
} */
.Line {
  width: 100%;
  height: 2px;
  background: #E0E0E0;
}

.thinLine {
  width: 100%;
  height: 1px;
  background: #E0E0E0;
}

p {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #4F4F4F;
}

.header {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #4F4F4F;
}

/* svg {
  color: red;
}

svg:hover {
  color: black;
} */

.siparisArsiv {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.siparisArsiv:hover {
  cursor: pointer;
  color: black;
}


.ApproverBodyContainer {

  background: #32C5FF;

  background: var(--color-primary);
}



.BannerText {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 36px;
  color: #F2F2F2;
  text-shadow: 0px 4px 8px rgba(0, 0, 0, 0.35);
}

.BannerTitle {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 48px;
  align-items: center;
  color: white;
  /*text-shadow: 2px 2px 3px black
  /* 0px 4px 8px rgba(0, 0, 0, 0.35); */
}


.siparisArsivTable table th {
  border: 1px solid rgba(132, 139, 200, 0.18);
  border: 1px solid var(--color-light);
  color: white;
  background: #32C5FF;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  padding: 0.8rem;
  left: 0;
  margin: 0px;
}

.siparisArsivTable table tbody td {
  height: 2.8rem;
  padding: 0.8rem;
  border: 1px solid rgba(132, 139, 200, 0.18);
  border: 1px solid var(--color-light);
  color: #677483;
  color: var(--color-dark-variant);
}

.siparisBodyContainer table thead {
  position: -webkit-sticky;
  position: sticky;
  z-index: 5;
  inset-block-start: 0;
  /* "top" */
}

.image-container {
  max-width: 100%;
  /* Resim maksimum genişlikte olacak */
  max-height: 100%;
  /* Resim maksimum yükseklikte olacak, ekran boyutunu aşmaz */
  overflow: auto;
  /* Ekrandan büyük resimler için otomatik kaydırma çubuklarını göster */
}

.HistoryTable table th,
.postList table th,
.approverList table th {
  border: 1px solid rgba(132, 139, 200, 0.18);
  border: 1px solid var(--color-light);
  color: white;
  background: #32C5FF;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  padding: 0.8rem;
  left: 0;
  margin: 0px;
}

.HistoryTable table tbody td,
.postList table tbody td,
.approverList table tbody td {
  height: 3rem;
  padding: 0.8rem;
  border: 1px solid rgba(132, 139, 200, 0.18);
  border: 1px solid var(--color-light);
  color: #677483;
  color: var(--color-dark-variant);
}

.approverFilter {
  padding: 20px;
  background-color: white;
  display: flex;
  margin-left: auto;
  margin-right: auto;
}

.approverFilter>div {
  padding: 0 0.5rem;
}

.filterButton {
  margin: 50%+3px 0;
}

.statusDropdown {
  width: 300px;
}

.sDates,
.eDates {
  position: relative;
}

.sDates>input,
.eDates>input {
  padding: 0.1rem;
  color: #677483;
  color: var(--color-dark-variant);
  position: relative;
  top: 30%;
  margin: 1px;

}

.approverFilter>div>p {
  border-bottom: #969595 2px solid;

}

.dateArea {
  width: 10rem;
  height: 43px;

}

.sDates:first-child {
  margin: 30px;
}

.testDate {
  margin: 0 10px 0 0;
}



.approverList table {
  width: auto;
  background: #32C5FF;
  padding: 1.8rem;
  padding: var(--card-padding);
  text-align: center;
  /* box-shadow: var(--box-shadow); */
  transition: all 300ms ease;
  border-collapse: separate;
  border-spacing: 0px;
  table-layout: fixed;
  display: block;
  overflow: auto;
  padding: 0px;
  white-space: nowrap;

}
.postList table{
  background: #32C5FF;
  padding: 1.8rem;
  padding: var(--card-padding);
  text-align: center;
  /* box-shadow: var(--box-shadow); */
  transition: all 300ms ease;
  border-collapse: separate;
  border-spacing: 0px;
  overflow: auto;
  padding: 0px;
  white-space: nowrap;
}

.approverList th,
.approverList td,
.postList th,
.postList td {
  background-color: white;
}


.stickyColumn {
  position: -webkit-sticky;
  position: sticky;
  right: -1px;
  z-index: 1;
  background-color: white;
}

.stickyColumn2 {
  position: -webkit-sticky;
  position: sticky;
  right: 68px;
  z-index: 1;
  background-color: white;
}

.stickyColumn3 {
  position: -webkit-sticky;
  position: sticky;
  right: 0;
  background-color: white;
  bottom: 0;
  z-index: 2;
}

.ActiveInput {
  background-color: rgb(248, 248, 248) !important;
}

.DisabledInput {
  background-color: rgb(230, 230, 230) !important;
}

.postTable tr{
  table-layout: fixed;
  width: 100%;
}
