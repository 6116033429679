.modalContainer {
  padding: 0 px 0 px;
}

.ui.modal {
  width: 1000px;
}

.modalBodyContainer {
  margin: 15px 10px;
}

/* font-family: 'Montserrat', sans-serif;
font-family: 'Roboto', sans-serif; */

.modalLine {
  margin-top: 5px;

  color: #F2F2F2;
  background-color: #F2F2F2;
  height: 5;
}

.modalHeader {
  display: flex;
  padding: 10px 25px 5px 15px;
  justify-content: space-between;
  align-items: center;
}

.modalTitle {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
}

.modalCloseButton {
  padding: 10px 10px;
  background-color: white;
}

.modalCloseButton:hover {
  background-color: #ebebeb;
}

.modalFooter {
  clear: both;
  margin-bottom: 20px;
}

.ui.modal>.content {
  padding: 0px;
}

.confirmModal.ui.modal>.content,
.denyConfirmModal.ui.modal>.content,
.approveConfirmModal.ui.modal>.content,
.sendBackConfirmModal.ui.modal>.content,
.historyModal.ui.modal>.content,
.detailsModal.ui.modal>.content {
  display: block;
  width: 100%;
  font-size: 1em;
  line-height: 1.4;
  padding: 1.5rem;
  background: #fff;
}

.selectedBorderBox {
  margin: auto;
  border: #333 2px solid;
  border-top: none;
  border-radius: 0 0 5px 5px;
  width: 80%;

  transition: 2s opacity ease-in-out, 2s transform ease-in-out;
}



.entryModalButton {
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  font-size: 14px;
  cursor: pointer;
  display: block;
  background: #32C5FF;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.3);
  border-radius: 4px;
}

.uploadedPicContainer{
  position: relative;
  background-color: #000;
  color: #fff;
  padding: 8px;
  border-radius: 20px;
  margin-top: 40px;
}

.uploadedPicContainer:after {
  content: '';
  position: absolute;
  top: -58px;
  left: 50%;
  margin-left: -30px;
  border-width: 30px;
  border-style: solid;
  border-color: transparent transparent #000 transparent;
}

.uploadedPicInner{
  border-radius: 20px;
  display: flex;
  flex-direction: column;
}

.descriptionInput {
  resize: none;
  overflow: hidden;
  min-height: 50px;
  max-height: 100px;
}
.selectedFileName{
  text-align: center;
  color: #fff !important;
  margin-top: 10px;
}